<div *ngIf="hasPendingRequests() || isAuthenticating()" class="container">
  <div class="box loading-panel has-text-centered">
    <span class="">
      <i class="fas fa-spinner fa-spin fa-2x"></i>
    </span>
  </div>
</div>
<!-- Header -->
<nav id="top-header" class="site-top-header navbar has-shadow is-transparent" role="navigation"
  aria-label="main navigation">
  <div class="navbar-menu">
    <div class="navbar-start">
      <a routerLink="/home" class="navbar-item">
        <span class="home-link">
          <i class="fas fa-home fa-2x"></i>
        </span>
      </a>
      <span class="nav-title">Research Registration</span>
    </div>
    <div class="navbar-brand">
      <img src="assets/images/mayo-min.png" alt="Mayo Clinic logo" class="nav-logo" />
    </div>
    <div class="navbar-end" [ngClass]="{ 'hide-content-space': !showNavbarEnd }">
      <div class="navbar-item has-search">
        <div *ngIf="hideSearch" class="has-search-input">
          <input class="input searchBox is-radiusless" type="text" placeholder="Study, Patient ID"
            name="searchHeader" />
        </div>
        <div class="icon">
          <a class="button is-radiusless" (click)="hideSearch = !hideSearch" [ngClass]="{
              'show-search': !hideSearch,
              'hide-search': hideSearch
            }">
            <i class="fas fa-search fa-2x" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <div class="navbar-item has-dropdown current-user-div" id="current-user"
        [ngClass]="{ 'is-active': showCurrentUser }">
        <div class="navbar-link icon is-medium is-arrowless">
          <a class="button is-radiusless" (click)="showCurrentUser = !showCurrentUser" [ngClass]="{
              'show-search': !showCurrentUser,
              'hide-search': showCurrentUser
            }">
            <i class="far fa-user-circle fa-2x" aria-hidden="true"></i>
          </a>
        </div>
        <!-- /.navbar-link -->
        <div class="navbar-dropdown is-radiusless sign-out-box is-boxed is-right">
          <a id="user-details" class="navbar-item user-details-div" *ngIf="currentUser">

            {{ currentUser.firstName }}

            {{ currentUser.lastName }} ({{
            currentUser.userPk
            }}) <br />
          </a>
          <a id="version-details" class="navbar-item user-details-div">
            Version:

            {{ appVersion.version }}
            <br />
          </a>
          <hr class="navbar-divider navbar-separator" />
          <div id="sign-out-id" class="navbar-item has-text-centered sign-out-div">
            <a class="button sign-out is-radiusless" (click)="logout()">
              <span class="icon">
                <i class="fas fa-sign-out-alt"></i>
              </span>
              <span>Sign out</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- /.navbar-end -->
  </div>
  <!-- /.navbar-menu -->
</nav>

<mc-toast-messages></mc-toast-messages>

<div class="columns" id="page-container">
  <div class="column is-clipped" id="main-container">
    <router-outlet *ngIf="!authenticating"></router-outlet>
  </div>
</div>
