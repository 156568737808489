/*The Decorator for this class - AppModule its of type module class.*/
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ComponentFactoryResolver,
  Injector,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/* Idle/Keepalive module for session timeout */
// This includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { LibraryVariableComponent } from 'app/admin/library-variable/library-variable.component';
import { AppRoutingModule } from 'app/app-routing.module';
import { AuthGuard } from 'app/auth-guard.service';
import { McFormFieldDirective } from 'app/common/directives/mc-form-field/mc-form-field.directive';
import { PreviewFormModule } from 'app/common/preview-form/preview-form.module';
import { AccrualSettingsService } from 'app/common/services/accrual-settings.service';
import { DemographyService } from 'app/common/services/demography/demography.service';
import { FormsDashboardService } from 'app/common/services/forms/forms-dashboard.service';
import { NotificationDashboardService } from 'app/common/services/notification/notification-dashboard.service';
import { SchemaDashboardService } from 'app/common/services/schema/schema-dashboard.service';
import { HomePageModule } from 'app/home/home-page.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AdminModule } from './admin/admin.module';
import { GroupNotificationModule } from './admin/group-notification/group-notification.module';
import { AppConfigLoaderService } from './app-config/app-config-loader.service';
/*npm packages external modules*/
import { AppConfigModule } from './app-config/app-config.module';
import { AppConfigService } from './app-config/app-config.service';
import { AppInjector } from './app-injector';
/*declarations = the view classes that belong to this module. App Component is also the root or bootstrap component*/
import { AppComponent } from './app.component';
// Directives
import { FormMessagesDirective } from './common/directives/form-messages/form-messages.directive';
import { McTableModule } from './common/directives/table/mc-table.module';
import { ExportFileService } from './common/services/export-file.service';
import { Helper } from './common/services/helper.service';
import { RulesService } from './common/services/nav-rules/rules-service.service';
import { NewPatientService } from './common/services/patient/new-patient.service';
import { PersonService } from './common/services/person.service';
import { ReportsService } from './common/services/reports.service';
import { SearchService } from './common/services/search.service';
/* Service */
import { ErrorHttpInterceptor } from './common/services/service-http-error-interceptor.service';
import { ServiceHttpInterceptor } from './common/services/service-http-interceptor.service';
import { StudyCreateService } from './common/services/study-create.service';
import { StudyServiceBase } from './common/services/study-service-base.service';
import { StudySetupService } from './common/services/study-setup.service';
import { StudyTemplateService } from './common/services/study-template/study-template.service';
import { ToastService } from './common/services/toast.service';
import { UserService } from './common/services/user.service';
/*Feature Modules */
import { SharedModule } from './common/shared.module';
import { ToastMessagesComponent } from './common/toast-messages/toast-messages.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { PatientModule } from './patient/patient.module';
import { ReportsModule } from './reports/reports.module';
import { ActiveContactsComponent } from './study/active-contacts/active-contacts.component';
import { ActiveDosageComponent } from './study/active-dosage/active-dosage.component';
import { AncillaryStudiesComponent } from './study/ancillary-studies/ancillary-studies.component';
import { CaseEventComponent } from './study/case-event/case-event.component';
// Common Components
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalRedirectComponent } from '@azure/msal-angular';
import { SchemaManagementService } from './common/services/schema/schema-management.service';
import { StudySignOffService } from './common/services/study-sign-off.service';
import { MsalApplicationModule } from './msal/msal-application.module';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { CreateReservationComponent } from './study/create-reservation/create-reservation.component';
import { ReservationConfirmationComponent } from './study/reservation-confirmation/reservation-confirmation.component';
import { SlotAvailabilityComponent } from './study/slot-availability/slot-availability.component';
import { SlotReservationsComponent } from './study/slot-reservations/slot-reservations.component';
import { StudyComponent } from './study/study.component';
import { TokenValidationGuard } from './token-validation.guard';

export function init_user(userService: UserService) {
  return () => userService.initializeUserData();
}

/*providers - collection of all services available and are used by all parts of this app.*/
/*declare all the compoenents you create here - mapped from the app-routing.module.ts here*/
@NgModule({
  declarations: [
    AppComponent,
    FormMessagesDirective,
    McFormFieldDirective,
    StudyComponent,
    ToastMessagesComponent,
    LibraryVariableComponent,
    ActiveContactsComponent,
    CaseEventComponent,
    AncillaryStudiesComponent,
    ActiveDosageComponent,
    NotAuthorizedComponent,
    CreateReservationComponent,
    SlotReservationsComponent,
    ReservationConfirmationComponent,
    PagenotfoundComponent,
    SlotAvailabilityComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    MatIconModule,
    MatPaginatorModule,
    SharedModule,
    NgIdleKeepaliveModule.forRoot(),
    ReactiveFormsModule,
    AppConfigModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
    McTableModule,
    AppRoutingModule,
    HomePageModule,
    AdminModule,
    PatientModule,
    GroupNotificationModule,
    PreviewFormModule,
    ReportsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatChipsModule,
    ScrollingModule,
    MsalApplicationModule.forRoot()
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_user,
      deps: [
        UserService,
        AppConfigLoaderService,
        HttpClient,
        ComponentFactoryResolver,
      ],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ServiceHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    AuthGuard,
    MatIconRegistry,
    ToastService,
    NewPatientService,
    SearchService,
    StudyServiceBase,
    DemographyService,
    ExportFileService,
    StudySetupService,
    StudyCreateService,
    AccrualSettingsService,
    Helper,
    PersonService,
    SchemaDashboardService,
    SchemaManagementService,
    NotificationDashboardService,
    FormsDashboardService,
    RulesService,
    UserService,
    ReportsService,
    StudyTemplateService,
    StudySignOffService,
    DatePipe,
    MsalBroadcastService,
    MsalGuard,
    TokenValidationGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(
    injector: Injector,
    public matIconRegistry: MatIconRegistry,
  ) {
    // Store module's injector in the AppInjector class
    AppInjector.setInjector(injector);
    {
      //  matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    }
  }
}
