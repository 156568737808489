import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { Observable } from "rxjs";
import { AuthService } from "./common/services/auth.service";

/**
 * This is a wrapper around the MsalGuard that first checks to see if the OIDC refresh token is expired in localStorage.
 * If it is, all MSAL-related localStorage entries will be cleared before calling MsalGuard.canActivate().
 */
@Injectable()
export class TokenValidationGuard {
  constructor(
    private authService: AuthService,
    private msalGuard: MsalGuard
  ) { }

  /**
   * Clears all MSAL-related entries from local storage is refresh token is expired.
   */
  private validateRefreshToken() {
    const refreshTokenExpiration: Date | null = this.authService.getRefreshTokenExpirationDate();
    const currentDate: Date = new Date();

    if (refreshTokenExpiration !== null && refreshTokenExpiration <= currentDate) {
      this.authService.clearCache();
    }
  }

  /**
   * Checks if OIDC refresh token has expired. If it has, this means user's session has expired and we need to get all new tokens.
   * All MSAL-related localStorage entries will be cleared before MsalGuard.canActivate() is called.
   * 
   * @param route The route being navigated to
   * @param state The route state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
    this.validateRefreshToken();
    return this.msalGuard.canActivate(route, state);
  }

  /**
   * Checks if OIDC refresh token has expired. If it has, this means user's session has expired and we need to get all new tokens.
   * All MSAL-related localStorage entries will be cleared before MsalGuard.canActivateChild() is called.
   * 
   * @param route The route being navigated to
   * @param state The route state
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
    this.validateRefreshToken();
    return this.msalGuard.canActivateChild(route, state);
  }

}