import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MsalBroadcastService } from "@azure/msal-angular";
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType } from "@azure/msal-browser";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { ListOptionModel } from "app/common/model/list-option";
import { ModalDialogArg } from "app/common/model/modal-dialog-args";
import { ToastService } from "app/common/services/toast.service";
import { Subscription } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { environment } from "../environments/environment";
import { AppUser } from "./common/model/app-user";
import { AppVersion } from "./common/model/app-version";
import { AuthService } from "./common/services/auth.service";
import { UserService } from "./common/services/user.service";

@Component({
  selector: "mc-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  timedOut = false;
  idleWarningModalDisplayed = false;

  showNavbarEnd = true;
  hideSearch: boolean;
  title = "app works by running ng serve --host 0.0.0.0 --open!";
  subscriptionList: Subscription[] = [];
  pendingHttpRequestCount = 0;
  public environmentName: string = environment.envName;
  showSidebar = false;

  showModal = true;

  currentUser: AppUser = null;

  siteSelected: ListOptionModel = null;
  selectedUserId: string = null;

  showCurrentUser = false;


  versionUrl = '/assets/version.json';
  appVersion: AppVersion = { "version": "unknown" };

  sites: ListOptionModel[] = [
    {
      listValue: "ADM403",
      listLabel: "Statistical and Data Management Center - Mayo",
    },
    {
      listValue: "ALLIANCE",
      listLabel: "Alliance for Clinical Trials in Oncology",
    },
    { listValue: "AZ020", listLabel: "Mao Clinic in Arizona" },
    { listValue: "CA384", listLabel: "PCR Oncology" },
    { listValue: "DC018", listLabel: "Sibley Memorial Hospital" },
    { listValue: "FL020", listLabel: "UF Cancer Center at Orlando Health" },
    { listValue: "FL080", listLabel: "Mayo Clinic in Florida" },
  ];

  authenticating: boolean = false;
  acquiringToken: boolean = false;

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router,
    private userService: UserService,
    private idle: Idle,
    private keepalive: Keepalive,
    private viewContainer: ViewContainerRef,
    private ref: ChangeDetectorRef,
    private httpClient: HttpClient,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.subscriptionList.push(
      toastService.pendingHttpCountPosition$.subscribe(pendingCount => {
        this.pendingHttpRequestCount = pendingCount;
        this.ref.detectChanges();
      })
    );

    // Display loading indicator on page if logging in through OIDC
    this.subscriptionList.push(
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.Login)
        )
        .subscribe(() => {
          this.authenticating = true;
          this.ref.detectChanges();
        })
    );

    // Display loading indicator on page if acquiring OIDC access token via redirects
    this.subscriptionList.push(
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_START && (msg.interactionType === InteractionType.Redirect || msg.interactionType === InteractionType.Silent))
        ).subscribe(() => {
          this.acquiringToken = true;
          this.ref.detectChanges();
        })
    );

    // Stop displaying loading indicator on page when done acquiring access token via redirects
    // When we get a new access token, parse the account from the payload and set it as the active account for the MSAL library
    this.subscriptionList.push(
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === (EventType.ACQUIRE_TOKEN_SUCCESS || EventType.ACQUIRE_TOKEN_FAILURE) && (msg.interactionType === InteractionType.Redirect || msg.interactionType === InteractionType.Silent))
        ).subscribe((msg: EventMessage) => {
          this.acquiringToken = false;
          this.ref.detectChanges();

          if (msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
            const payload = msg.payload as AuthenticationResult;
            this.authService.setActiveAccount(payload.account);
          }
        })
    );

    // need to ensure we react only when the navigation ends
    this.subscriptionList.push(
      router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((url: any) => {
          window.scrollTo(0, 0);
        })
    );

    /* Configure keep alive */
    /* The F5 authentication times out after 15 minutes of inactivity */
    // Sets an idle timeout of 50 minutes (in seconds).  Warn the user after x idle minutes that they are going to be logged out.
    idle.setIdle(50 * 60);
    // Sets a timeout period of 5 minutes (in seconds). After (setIdle + setTimeout) seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(10 * 60);
    // Sets the default interrupts, in this case, things like clicks, scrolls, touches to the document will reset the idle timeout
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    /* Sets idle timeout subscriptions and messages */
    // Show popup when the idle period has been reached
    idle.onTimeoutWarning.subscribe((countdown) => {
      const modalArg = new ModalDialogArg(
        "modal-warn",
        "Custom",
        "You will be logged out soon. Click OK to stay logged in."
      );
      if (!this.idleWarningModalDisplayed) {
        this.idleWarningModalDisplayed = true;
        this.userService
          .showModalDialog(this.viewContainer, modalArg)
          .subscribe((actionResult) => {
            // Reset idle timeout if the user responds
            this.resetIdle();
            this.idleWarningModalDisplayed = false;
          });
      }
    });
    // Update the modal message, and log the user out
    idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.authService.logout();
    });

    // sets the ping interval to 10 minutes (in seconds), this contacts the server and resets the F5 idle timer
    keepalive.interval(10 * 60);
    keepalive.request(
      new HttpRequest("GET", "/assets/html/keepalive.html", {
        headers: new HttpHeaders()
          .set("Cache-Control", "no-cache, no-store, must-revalidate")
          .set("Pragma", "no-cache")
          .set("Expires", "0"),
      })
    );

    // Log that the keeplive is happening
    // keepalive.onPing.subscribe(() => {console.log("Pinging...");});
    // keepalive.onPingResponse.subscribe(() => {console.log("Pinged");});

    this.resetIdle();
  }

  // Reset method for keepalive functionality
  resetIdle() {
    this.idle.watch();
    this.timedOut = false;
  }

  hasPendingRequests() {
    return this.pendingHttpRequestCount > 0;
  }

  toggleSideBarMenu() {
    this.showSidebar = !this.showSidebar;
  }

  /*
    This will logout the user and redirect to the timeout page.
  */
  logout() {
    this.authService.logout();
  }

  ngOnInit() {
    this.showModal = false;

    // When OIDC authentication complete, set currentUser
    this.subscriptionList.push(
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None)
        )
        .subscribe((status: InteractionStatus) => {
          this.authenticating = false;
          this.ref.detectChanges();

          if (status === InteractionStatus.None) {
            this.currentUser = this.userService.appUserEntity;
            if (this.currentUser != null) {
              this.selectedUserId = this.currentUser.userId;
            } else {
              // User was not found, so show error page
              this.showNavbarEnd = false;
              this.router.navigate(["/not-authorized"], { skipLocationChange: true });
            }
          }
        })
    );

    this.httpClient.get<AppVersion>(this.versionUrl).subscribe(res => {
      this.appVersion = res;
    });

    const agent = window.navigator.userAgent.toLowerCase();
    // if (!((agent.indexOf('chrome') > -1 && !!(<any>window).chrome))) { -- if not chrome
    if (agent.indexOf("trident") > -1) {
      // if IE
      const variableData =
        "Chrome and Firefox are the recommended browsers for the best experience with this application.  Internet Explorer (IE) is not recommended.";
      const modalArg = new ModalDialogArg("modal-warn", "Custom", variableData);
      this.userService
        .showModalDialog(this.viewContainer, modalArg)
        .subscribe((actionResult) => {
          // do nothing stay on the same page.
        });
    }

    this.startMonitoringAccessTokenExpiration();
  }

  /**
   * Starts an interval that runs every 60 seconds that gets the access token expiration date.
   * If the user's current time is within 5 minutes of the token's expiration date, get a new token.
   */
  private startMonitoringAccessTokenExpiration() {
    const msPerMinute: number = 60000; // 60 * 1000ms = 60 seconds

    setInterval(() => {
      const accessTokenExpirationDate: Date = this.authService.getAccessTokenExpirationDate();
      if (accessTokenExpirationDate) {
        const fiveMinutePriorInMs: number = accessTokenExpirationDate.valueOf() - (msPerMinute * 5); // Access token expiration date - 5 minutes
        const fiveMinutePriorDate: Date = new Date(fiveMinutePriorInMs);
        if (new Date() >= fiveMinutePriorDate) {
          this.authService.acquireTokenSilent();
        }
      }
    }, msPerMinute);
  }

  ngOnDestroy() {
    this.subscriptionList.forEach((x) => {
      x.unsubscribe();
    });
  }

  /**
   * Compare function for the select control on the ui
   * @param a The first option to compare
   * @param b the second option to compare
   */
  compareSiteFn(a: ListOptionModel, b: ListOptionModel) {
    return a && b && a.listValue == b.listValue;
  }

  /**
   * Shows the role selection modal
   * @param  The event raised by the button
   */
  showRoleModal($event: Event) {
    if ($event) {
      $event.stopPropagation();
    }
    this.showModal = true;
    this.showCurrentUser = false;
  }


  /**
   * True if userId emulation is being used
   */
  shouldDisplayReset(): boolean {
    return (
      this.userService.authenticatedUserEntity.userPk !=
      this.userService.appUserEntity.userPk
    );
  }


  isAuthenticating() {
    return this.authenticating || this.acquiringToken;
  }
}
