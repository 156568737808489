import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LibraryVariableComponent } from 'app/admin/library-variable/library-variable.component';
import { StudyCreateComponent } from 'app/admin/study-create/study-create.component';
import { CanDeactivateGuard } from 'app/can-deactivate-guard.service';
import * as activityConst from 'app/common/model/activity-name-constants';
// Components
import { BrowserUtils } from '@azure/msal-browser';
import { HomeComponent } from 'app/home/home.component';
import { NotAuthorizedComponent } from 'app/not-authorized/not-authorized.component';
import { PagenotfoundComponent } from 'app/pagenotfound/pagenotfound.component';
import { ReportDashboardComponent } from 'app/reports/report-dashboard.component';
import { CaseEventComponent } from 'app/study/case-event/case-event.component';
import { StudyComponent } from 'app/study/study.component';
import { NotificaitionSearchComponent } from './admin/notificaition-search/notificaition-search.component';
import { AuthGuard } from './auth-guard.service';
import { PatientRoutingModule } from './patient/patient-routing.module';
import { CreateReservationComponent } from './study/create-reservation/create-reservation.component';
import { TokenValidationGuard } from './token-validation.guard';


const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [TokenValidationGuard],
        data: {
            breadcrumb: 'Home',
            activityName: activityConst.ACTIVITY_HOME
        }
    },
    {
        path: 'studies/:studyId',
        component: StudyComponent,
        canActivate: [TokenValidationGuard, AuthGuard],
        data: {
            breadcrumb: 'Study Landing',
            activityName: activityConst.ACTIVITY_STUDY_LANDING
        }
    },

    {
        path: 'admin/studies/create',
        component: StudyCreateComponent,
        canActivate: [TokenValidationGuard, AuthGuard],
        data: {
            breadcrumb: 'Create Study',
            activityName: activityConst.ACTIVITY_STUDY_CREATE
        }
    },

    {
        path: 'admin/notification-search',
        component: NotificaitionSearchComponent,
        canActivate: [TokenValidationGuard, AuthGuard],
        data: {
            breadcrumb: 'Notification Search',
            activityName: activityConst.ACTIVITY_NOTIFICATION_RECENT
        }
    },

    {
        path: 'admin/library-variable',
        component: LibraryVariableComponent,
        canActivate: [TokenValidationGuard, AuthGuard],
        data: {
            breadcrumb: 'Variable Library',
            activityName: activityConst.ACTIVITY_VARIABLE_LIBRARY
        }
    },
    {
        path: 'studies/:studyId/registration/:patientId/:caseDetailId/:caseEventId',
        loadChildren: () => import('app/patient-registration/patient-registration.module').then(m => m.PatientRegistrationModule),
        canActivateChild: [TokenValidationGuard, AuthGuard],
        data: {
            breadcrumb: "Subject Registration",
            activityName: activityConst.ACTIVITY_PATIENT_REGISTRATION
        }
    },
    {
        path: 'studies/:studyId/case-event',
        component: CaseEventComponent,
        canActivate: [TokenValidationGuard, AuthGuard],
        data: {
            breadcrumb: 'CEvent Landing',
            activityName: activityConst.ACTIVITY_STUDY_CASE_EVENT
        }
    },
    {
        path: 'studies/:studyId/patients/reservation/:subjectReservationId',
        component: CreateReservationComponent,
        canActivate: [TokenValidationGuard, AuthGuard],
        data: {
            breadcrumb: 'Create Reservation',
            activityName: activityConst.ACTIVITY_SLOT_RESERVATION
        }
    },

    {
        path: 'admin',
        canActivate: [TokenValidationGuard, AuthGuard],
        pathMatch: 'full',
        loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule),
        data: {
            breadcrumb: 'Admin',
            activityName: activityConst.ACTIVITY_ADMIN_DASHBOARD
        }
    },
    {
        path: 'admin/studies/:studyId/setup',
        loadChildren: () => import('app/admin/study-setup/study-setup.module').then(m => m.StudySetupModule),
        canActivateChild: [TokenValidationGuard, AuthGuard],
        data: {
            breadcrumb: 'Study Setup',
            activityName: activityConst.ACTIVITY_STUDY_SETUP
        }
    },
    {
        path: 'admin/studies/:studyId/sign-off',
        loadChildren: () => import('app/admin/study-sign-off/study-sign-off.module').then(m => m.StudySignOffModule),
        canActivateChild: [TokenValidationGuard, AuthGuard],
        data: {
            breadcrumb: 'Study Sign Off',
            activityName: activityConst.ACTIVITY_STUDY_SIGN_OFF
        }
    },
    {
        path: 'admin/group-notification',
        loadChildren: () => import('app/admin/group-notification/group-notification.module').then(m => m.GroupNotificationModule),
        canActivateChild: [TokenValidationGuard, AuthGuard],
        data: {
            breadcrumb: 'Notification Setup',
            activityName: activityConst.ACTIVITY_NOTIFICATION_SETUP
        }
    },
    {
        path: 'admin/studies/:studyId/forms-setup/:formId',
        loadChildren: () => import('app/admin/forms/forms-setup.module').then(m => m.FormsSetupModule),
        canActivateChild: [TokenValidationGuard, AuthGuard],
        data: {
            breadcrumb: 'Forms Setup',
            activityName: activityConst.ACTIVITY_FORM_BUILDER
        }
    },
    {
        path: 'admin/studies/:studyId/randomization-simulation',
        loadChildren: () => import('app/admin/randomization-simulation/randomization-simulation.module').then(m => m.RandomizationSimulationModule),
        canActivateChild: [TokenValidationGuard, AuthGuard],
        data: {
            breadcrumb: 'Randomization Simulation',
            activityName: activityConst.ACTIVITY_STUDY_SIMULATION
        }
    },
    {
        path: 'Reports',
        component: ReportDashboardComponent,
        canActivate: [TokenValidationGuard, AuthGuard],
        data: {
            breadcrumb: 'Reports',
            activityName: activityConst.ACTIVITY_REPORTS
        }
    },
    {
        path: 'admin/study-templates',
        loadChildren: () => import('app/admin/study-template/study-template.module').then(m => m.StudyTemplateModule),
        canActivate: [TokenValidationGuard, AuthGuard],
        data: {
            breadcrumb: 'Study Templates',
            activityName: activityConst.ACTIVITY_STUDY_TEMPLATE
        }
    },
    {
        path: 'not-authorized',
        component: NotAuthorizedComponent
    },

    //Wild Card Route for 404 request
    {
        path: '**', pathMatch: 'full',
        component: PagenotfoundComponent
    },

];

@NgModule({
    imports: [
        PatientRoutingModule,
        RouterModule.forRoot(
            routes,
            {
                preloadingStrategy: PreloadAllModules,
                enableTracing: false,
                initialNavigation: (!BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()) ? 'enabledBlocking' : 'disabled', // Don't perform initial navigation in iframes
            }
        )
    ],
    exports: [RouterModule],
    providers: [
        CanDeactivateGuard
    ]
})

export class AppRoutingModule { }
