import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { AppConfigService } from 'app/app-config/app-config.service';
import { MsalAccessToken } from '../model/msal-access-token';
import { MsalRefreshToken } from '../model/msal-refresh-token';


/**
 * Wrapper for MSAL Auth Service
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly ACCESS_TOKEN_LABEL: string = 'accesstoken';
  private readonly REFRESH_TOKEN_LABEL: string = 'refreshtoken';

  constructor(
    private msalAuthService: MsalService
  ) { }

  /**
   * Gets the account information for the active user
   * @returns account information for the currently logged in user
   */
  getUserAccountInfo(): AccountInfo | null {
    return this.getActiveAccount();
  }

  logout() {
    const idToken: string = this.getActiveAccount()?.idToken;

    localStorage.clear();

    this.msalAuthService.logoutRedirect({
      postLogoutRedirectUri: AppConfigService.runConfig.msalConfig.redirectUri,
      idTokenHint: idToken
    });
  }

  setActiveAccount(account: AccountInfo) {
    this.msalAuthService.instance.setActiveAccount(account);
  }

  /**
   * Gets the active MSAL account using the msalService
   */
  private getActiveAccount(): AccountInfo | null {
    let currentAccount = this.msalAuthService.instance.getActiveAccount();
    if (!currentAccount && this.msalAuthService.instance.getAllAccounts()?.length > 0) {
      currentAccount = this.msalAuthService.instance.getAllAccounts()[0];
      this.msalAuthService.instance.setActiveAccount(currentAccount);
    }

    return currentAccount;
  }

  /**
   * Returns the key for the specified token value in local storage.
   * NOTE: tokenType is case-sensitive
   */
  private getLocalStorageKeyForToken(tokenType: string): string {
    const keys: string[] = Object.keys(localStorage);
    const tokenKeys: string[] = keys.filter(key => key.includes(tokenType) && key.includes(AppConfigService.runConfig.msalConfig.issuer) && key.includes(AppConfigService.runConfig.msalConfig.clientId));

    if (tokenKeys.length === 1) {
      return tokenKeys[0];
    }

    return '';
  }

  /**
   * Returns the expiration date and time for the access token in local storage
   */
  getAccessTokenExpirationDate(): Date {
    const storageItem: string = localStorage.getItem(this.getLocalStorageKeyForToken(this.ACCESS_TOKEN_LABEL));
    if (storageItem) {
      const token: MsalAccessToken = JSON.parse(storageItem) as MsalAccessToken;
      const expirationDate: Date = new Date(parseInt(token?.expiresOn) * 1000); // access token expiresOn is UTC epoch timestamp from AAD 
      return expirationDate;
    }

    return null;
  }

  /**
   * Returns the expiration date and time for the access token in local storage
   */
  getRefreshTokenExpirationDate(): Date {
    const storageItem: string = localStorage.getItem(this.getLocalStorageKeyForToken(this.REFRESH_TOKEN_LABEL));
    if (storageItem) {
      const token: MsalRefreshToken = JSON.parse(storageItem) as MsalRefreshToken;
      const expirationDate: Date = new Date(parseInt(token?.expiresOn) * 1000); // access token expiresOn is UTC epoch timestamp from AAD 
      return expirationDate;
    }

    return null;
  }

  /**
   * Clears the current access token from local storage and shows a popup that retrieves a new access token automatically
   */
  acquireTokenSilent() {
    localStorage.removeItem(this.getLocalStorageKeyForToken(this.ACCESS_TOKEN_LABEL));

    this.msalAuthService.acquireTokenPopup({
      account: this.getActiveAccount(),
      scopes: [AppConfigService.runConfig.msalConfig.scope],
    });
  }

  /**
   * Clears the MSAL-related entries from the localStorage
   */
  clearCache() {
    this.msalAuthService.instance.clearCache();
  }
}
