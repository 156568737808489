import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import * as globalConst from 'app/common/model/app-constants';
import { EventArg } from 'app/common/model/event-arg';
import { ModalDialogArg } from 'app/common/model/modal-dialog-args';
import { StudySetupService } from 'app/common/services/study-setup.service';
import { ToastService } from 'app/common/services/toast.service';
import * as _ from 'lodash';
import { Observable, of, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { GroupNotificationSubscription } from '../../common/model/group-notification-subscription';
import { NotificationCode } from '../../common/model/notification-code';
import { StudyNotification } from '../../common/model/study-notification';
import { NotificationDashboardService } from '../../common/services/notification/notification-dashboard.service';




@Component({
  selector: 'mc-notification-setup',
  templateUrl: './notification-setup.component.html',
  styleUrls: ['./notification-setup.component.scss']
})
export class NotificationSetupComponent implements OnInit , OnDestroy {

  /**
 * List of all the study components
 */
  sectionItems:
    { active: string, linkID: string, title: string, icon: string, path: string }[] = [
    { active: '', linkID: 'section1', title: 'Info', icon: 'fas fa-exclamation', path: 'notification-info' },
    { active: '', linkID: 'section4', title: 'Configuration', icon: 'fa fa-cog', path: 'configuration' },
    { active: '', linkID: 'section2', title: 'Recipients', icon: 'fas fa-users', path: 'recipients' },
    { active: '', linkID: 'section3', title: 'Message Text', icon: 'fa fa-paragraph', path: 'message-text' },
  ];

  currentUrl: any;
  /**
   * navigation excluded from deactivate check
   */
  excludedNavigation: string[] = ['notification-info', 'configuration', 'message-text', 'recipients', 'group-configuration'];

  studyId = 0;

  notificationId = 0;
  selectedNotification: StudyNotification ;

/**
 * Determines if the user selected a group notification from the study page
 */
  isNavigatedGroupNotification = false ;
  /**
   * true if the notifications are group notifications
   */
  isGroupNotifications = true;

  /**
   * Holds the unmodified study notification
   */
  originalNotification: StudyNotification ;

  private subscriptions: Subscription[] = [];

/**
 * Variable to determine if the form we're working on is new
 */
  isExistingForm = false;


  constructor(
    private viewContainer: ViewContainerRef,
    private studySetupService: StudySetupService,
    private notificationService: NotificationDashboardService,
    private route: ActivatedRoute,
    private toast: ToastService ,
    private router: Router) {
    this.subscriptions.push(
      studySetupService.changeEmitted$
        .subscribe(eventInfo => { this.parseEventInfo(eventInfo); })
    );
    // need to ensure we react only when the navigation ends
    this.subscriptions.push(router.events.pipe(filter(event => event instanceof NavigationStart))
      .subscribe((url: any) => {
        if (url.url) {
          this.currentUrl = url;
        }
      }));

  }

  ngOnInit() {
    this.notificationService.currentNotification = null;

    this.subscriptions.push(this.route.queryParams.subscribe(qParam => {
      const isNav = qParam['isGroupNotification'];
      this.subscriptions.push(this.route.parent.parent.params.subscribe(params => {
        this.studyId = params['studyId'];
        // if there is a study Id and the selected notification is a NOT a group notification
        if (this.studyId && this.studyId > 0) {
          this.isNavigatedGroupNotification = isNav == 'true'
          if (!this.isNavigatedGroupNotification) {
            this.isGroupNotifications = false;
          }
        }
        this.subscriptions.push(this.route.parent.params.subscribe(params => {
          this.notificationId = params['notificationId'];
          this.notificationService.isNavigatedGroupNotification = this.isNavigatedGroupNotification;
          this.setNavSections();
          if (this.isGroupNotifications) {
            this.getGroupNotification();
          } else {
            this.getStudyNotification();
          }
        }));
      }));
    }));
    this.notificationService.notificationSetup = this;
  }

  /**
   * Sets the nav Sections for left navigation display
   */
  setNavSections() {
    if (this.isGroupNotifications) {
      // TODO: Change this as necessary, reference to new components if needed
      this.sectionItems = [
        { active: '', linkID: 'section1', title: 'Info', icon: 'fas fa-exclamation', path: 'notification-info' },
        { active: '', linkID: 'section4', title: 'Configuration', icon: 'fa fa-cog', path: 'group-configuration' },
        { active: '', linkID: 'section2', title: 'Recipients', icon: 'fas fa-users', path: 'recipients' },
        { active: '', linkID: 'section3', title: 'Message Text', icon: 'fa fa-paragraph', path: 'message-text' }
      ]
    } else {
      this.sectionItems = [
      { active: '', linkID: 'section1', title: 'Info', icon: 'fas fa-exclamation', path: 'notification-info' },
      { active: '', linkID: 'section4', title: 'Configuration', icon: 'fa fa-cog', path: 'configuration' },
      { active: '', linkID: 'section2', title: 'Recipients', icon: 'fas fa-users', path: 'recipients' },
      { active: '', linkID: 'section3', title: 'Message Text', icon: 'fa fa-paragraph', path: 'message-text' },
    ];
    }
  }

  /**
   * Emits an event to notify the sub components of notification retried
  */
  notificationRetrieved() {
    const eventArg: EventArg = new EventArg(globalConst.EVENT_DATA_UPDATED, 'true');
    this.studySetupService.emitChange(eventArg);
  }

   /**
   * Emits an event to notify the sub components the notification was cancelled and data reset
  */
  notifyOfReset() {
    const eventArg: EventArg = new EventArg(globalConst.EVENT_DATA_RESET, 'true');
    this.studySetupService.emitChange(eventArg);
    this.notificationRetrieved();
  }


  /**
   * Retrieves the selected Group Notification
   */
  getGroupNotification() {
     if (this.notificationId > 0) {
      this.isExistingForm = true;
      // call services  to retrive the notification
      this.subscriptions.push(
        this.notificationService.getSingleGroupNotification(this.notificationId)
          .subscribe(result => {
            this.selectedNotification = result;
            // Set the notification
            this.notificationService.currentNotification = this.selectedNotification;
            this.cloneData(false);
            this.notificationRetrieved();
          }));
    } else {
     this.createBlankNotification();
    }
  }



  /**
   * Retrieves a notification if a valid id is passed or creates a mock on
  */
  getStudyNotification() {
    if (this.notificationId > 0) {
      this.isExistingForm = true;
      // call services  to retrive the notification
      this.subscriptions.push(this.notificationService.getSingleStudyNotification(this.studyId, this.notificationId)
        .subscribe(result => {
          this.selectedNotification = result;
          // Set the notification
          this.notificationService.currentNotification = this.selectedNotification;
          this.cloneData(false);
          this.notificationRetrieved();
        }));
    } else {
      this.createBlankNotification();
    }
  }

  /**
   * Creates a blank notification object
  */
  createBlankNotification() {
    // initialize a blank notification
    this.selectedNotification = {
        studyNumber: null,
        studyNotificationId: -1,
        subscribedToGroupNotification: this.isGroupNotifications ?
              new GroupNotificationSubscription(true, -1, 0)  : null,
        notificationId: -1,
        notificationRefNum: 0,
        versionNum: 0,
        notificationType: null,
        deliveryMethodType: new NotificationCode(1, 'email', 'email'),
        researchEntity: null,
        name: '',
        longDesc: '',
        subject: '',
        messageText: '',
        status: null,
        notes: '',
        modifiedTs: null,
        modifiedById: '',
        tupleVersion: 0,
        notificationAccrualDetail: {
          notificationAccrualDetailId: 0,
          accrualType: null,
          notificationByLevel: null,
          includeByCharacteristic: 0,
          patientCountMethod: null, // this should be set to 1 for a new notification?
          accrualPointType: null,
          accrualPoint: null
        },
        emailDeliveryDetail: null,
        notificationDistributionList: [],
        notificationStudyTypeExclusionList: [],
        groupNotificationEventList: [],
        studyNotificationEventList: [],
        studyNotificationGroupingFactorList: [],
        studyNotificationArmList: [],
        studyNotificationProblemTypeExclusionList: [],
        studyNotificationValidValueList: [],
        studyNotificationSlotGroupList: [],
        notificationApprovalTypeList:[]
      }
    // Set the notification
    this.notificationService.currentNotification = this.selectedNotification;
    this.cloneData(false);
    this.notificationRetrieved();
  }



  /**
   * This will Evaluate whether SAVE or CANCEL to be called.
   * @param  {EventArg} eventArg
   */
  parseEventInfo(eventArg: EventArg) {
    if (eventArg.eventName == globalConst.EVENT_SAVE_ACTION) {
      if (this.isGroupNotifications) {
        this.saveGroupNotificationChanges();
      } else {
        this.saveStudyNotificationChanges();
      }
    } else if (eventArg.eventName == globalConst.EVENT_CANCEL_ACTION) {
      // if there are changes...
      this.cancelChanges();

    }
  }


  /**
   * Prompts the user for confirmation and reverts the data to its original state
  */
  cancelChanges() {
    // if there were no changes ignore
    if (!this.isDataModified()) {
      return;
    }
   this.subscriptions.push(this.showConfirmationDialog()
    .subscribe(actionResult => {
      if (actionResult) {
        this.cloneData(true);
        this.notifyOfReset();

      }
    }))
  }


  /**
 * This will show confirmation dialog before cancel
 * @returns Observable - return Observable
 */
  showConfirmationDialog(): Observable<boolean> {
    const modalArg = new ModalDialogArg('modal-warn', 'Cancel', null);
    const result = this.notificationService.showModalDialog(this.viewContainer, modalArg);
    return result.asObservable().pipe(tap(x => {
      return of(x);
    }));
  }



  /**
   * Save the changes to the  Notification model
  */
  saveGroupNotificationChanges() {
    if (this.isDataModified()) {
      if (!this.isExistingForm) {
        this.notificationService.addGroupNotification(this.selectedNotification)
          .subscribe(result => {
            this.doFinalizeSave(result);
          },
          (err: any) => {
            if (err.status === 400) {
              if (err.status === 400) {
                if (err.error && err.error.message) {
                  const realMsg: string = err.error.message;
                  const msg = realMsg.substring(realMsg.indexOf(':') + 1, realMsg.length)
                  this.toast.sendMessage(msg, 'danger', null, true);
                }
              }
            }
          })
      } else {
        this.notificationService.updateGroupNotification(this.notificationId, this.selectedNotification)
          .subscribe(result => {
            this.doFinalizeSave(result);
          },
          (err: any) => {
            if (err.status === 400) {
              if (err.status === 400) {
                if (err.error && err.error.message) {
                  const realMsg: string = err.error.message;
                  const msg = realMsg.substring(realMsg.indexOf(':') + 1, realMsg.length)
                  this.toast.sendMessage(msg, 'danger', null, true);
                }
              }
            }
          })
      }
    }
  }


  /**
   * Save the changes to the study Notification model
  */
  saveStudyNotificationChanges() {
    if (this.isDataModified()) {
      if (!this.isExistingForm) {
        this.notificationService.addStudyNotification(this.studyId, this.selectedNotification)
          .subscribe(result => {
            this.doFinalizeSave(result);
          },
          (err: any) => {
            if (err.status === 400) {
              if (err.status === 400) {
                if (err.error && err.error.message) {
                  const realMsg: string = err.error.message;
                  const msg = realMsg.substring(realMsg.indexOf(':') + 1, realMsg.length)
                  this.toast.sendMessage(msg, 'danger', null, true);
                }
              }
            }
          })
      } else {
        this.notificationService.updateStudyNotification(this.studyId, this.notificationId, this.selectedNotification)
          .subscribe(result => {
            this.doFinalizeSave(result);
          },
          (err: any) => {
            if (err.status === 400) {
              if (err.error && err.error.message) {
                const realMsg: string = err.error.message;
                const msg = realMsg.substring(realMsg.indexOf(':') + 1, realMsg.length)
                this.toast.sendMessage(msg, 'danger', null, true);
              }
            }
          })
      }
    }
  }

  /**
   * handle save to display modal and refresh the data if needed
  */
  doFinalizeSave(newUpdatedData: StudyNotification) {
    let variableData: string = 'studyNumber:' + newUpdatedData.studyNumber; //this.studyId;
    this.selectedNotification = newUpdatedData;
    this.notificationService.currentNotification = this.selectedNotification;
    this.cloneData();
    this.isExistingForm = true;
    if (this.isGroupNotifications) {
      variableData = null;
    }

    const modalArg = new ModalDialogArg('custom-save', 'Save', variableData, 'Notification Landing Page');
    this.studySetupService.showPendingChangesDialog(this.viewContainer, modalArg)
      .subscribe(actionResult => {
        if (!actionResult) {
          let notification = '/admin/studies/' + this.studyId + '/setup/notification';
          if (this.isGroupNotifications) {
            notification = '/admin/group-notification/notification';
          }
          this.router.navigate([notification]);
        }
      });
  }






  /**
    * Helper method to clone original data
    */
  cloneData(rollback: boolean = false) {
    // this is to rollback when cancel is clicked.
    if (rollback) {
      this.selectedNotification = _.cloneDeep(this.originalNotification);
      this.notificationService.currentNotification = this.selectedNotification;

    } else {
      this.originalNotification = _.cloneDeep(this.selectedNotification);
    }
  }

  /**
   * Returns true if the studyNotification object has been modified
  */
  isDataModified(): boolean {
    return !_.isEqual(this.selectedNotification, this.originalNotification);
  }

   /**
 * Destroy implementation - closes all the subscriptions
 */
ngOnDestroy() {
  this.subscriptions.forEach(
    x => {
      x.unsubscribe();
      x.closed;
    }
  )
}


  /********* Pending Changes warning************** */
  /**
   * Implemenation of the canDeactivate that will be called when we navigate away
   *
   */
  canDeactivate(): Observable<boolean> | boolean {
    let shouldPrompt = false;
    if (this.currentUrl && this.currentUrl.url) {
      const urlToMatch = this.currentUrl.url.toLowerCase();
      const match = this.excludedNavigation.find(x => urlToMatch.indexOf(x.toLowerCase()) > -1);
      // true if there was NO match
     shouldPrompt = (match == undefined);
    }
    if (!this.isDataModified() || !shouldPrompt) {
      return true;
    }

    return this.showConfirmationDialog()
      .pipe(tap(x => {
        return of(x);
      }));
  }
  /*********  Pending Changes warning************** */





}
